import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { startSubscriptionProccess, subscribeToPortfolio, SubscriptionStepsSet, } from '@entities/portfolio';
import Button from '@shared/ui/buttons/Button';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { IsDefined } from '@utils/js-ts';
import { ContainerColumn } from '@src/components/styled';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import BidToQueueSelectExchangeStep from './SelectExchangeStep';
import BidToQueueEnterInvestmentStep from './EnterInvestmentStep';
import SelectQueuePositionStep from './select-queue-position-step/SelectQueuePositionStep';
const Container = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const Divider = styled.div(props => ({
    display: 'flex',
    width: '100%',
    height: 1,
    backgroundColor: props.theme.customColors.menu.border,
}));
const BidToQueueStepFactory = ({ step, index, }) => {
    if (step === 'select_exchange') {
        return _jsx(BidToQueueSelectExchangeStep, { stepNumber: index + 1 });
    }
    if (step === 'invest_amount') {
        return _jsx(BidToQueueEnterInvestmentStep, { stepNumber: index + 1 });
    }
    if (step === 'bid_amount') {
        return _jsx(SelectQueuePositionStep, { stepNumber: index + 1 });
    }
    return undefined;
};
const StartCopyingButton = ({ isLoading = false, onClick, }) => {
    const { t } = useTranslation();
    return (_jsx(Button, { variant: 'filled', color: 'success', size: 'large', isLoading: isLoading, onClick: onClick, fullWidth: true, children: t('portfolio.subscription.start_copy_trade') }));
};
// TODO: check if we can subscribe onto the portfolio if we are the trader
const BidToQueueStepper = ({ className = undefined, isLoading = false, }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { portfolio, isUserSubscribed, subscriptionData, completedSteps, } = useAppSelector(state => state.portfolioSubscription);
    const currentStep = useMemo(() => {
        const steps = Array.from(SubscriptionStepsSet);
        // NOTE: eslint complains about `for (const .. of ..)` loop here. check it
        for (let i = 0; i < steps.length; i += 1) {
            const step = steps[i];
            if (!completedSteps.includes(step)) {
                return [step, i];
            }
        }
        return undefined;
    }, [completedSteps]);
    // TODO: check it, since completedSteps is an array it might potentialy have the same size,
    // but with repeated steps
    const allStepsCompleted = useMemo(() => Array.from(SubscriptionStepsSet).length === completedSteps.length, [completedSteps]);
    const initSubscriptionProcess = () => {
        dispatch(startSubscriptionProccess());
    };
    const submit = () => {
        if (!IsDefined(portfolio) || !IsDefined(subscriptionData)) {
            return;
        }
        dispatch(subscribeToPortfolio());
    };
    const stopCopying = () => {
    };
    return (_jsxs(Container, { className: className, children: [
            // display completed steps
            completedSteps.map((step, idx) => (_jsxs(Container, { children: [_jsx(Divider, {}), _jsx(BidToQueueStepFactory, { step: step, index: idx })] }, step))), 
            // display current step
            IsDefined(subscriptionData) &&
                IsDefined(currentStep) && (_jsxs(Container, { children: [_jsx(Divider, {}), _jsx(BidToQueueStepFactory, { step: currentStep[0], index: currentStep[1] })] })), !isUserSubscribed &&
                !IsDefined(subscriptionData) && (_jsx(WithSkeleton, { isLoading: isLoading, width: 376, height: 48, children: _jsx(StartCopyingButton, { onClick: initSubscriptionProcess }) })), !isUserSubscribed &&
                IsDefined(subscriptionData) &&
                allStepsCompleted && (_jsx(StartCopyingButton, { isLoading: isLoading, onClick: submit })), isUserSubscribed && (_jsx(Button, { variant: 'filled', color: 'error', size: 'large', isLoading: isLoading, onClick: stopCopying, fullWidth: true, children: t('portfolio.subscription.stop_copy_trade') }))] }));
};
export default BidToQueueStepper;
